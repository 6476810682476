.inputTextContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    z-index: 1000;
}

.inputTextContainer img {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: -2.75rem;
    margin-left: calc(2.75rem / 2 - 1px);
    z-index: 2000;
}

.inputText {
    width: 100%;
    padding: 1.25rem 0rem 1.25rem 3.25rem;
    font-family: Epilogue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 0.5rem;
    border: 2px solid #CFCFCF;
    background: #fff;
}
@import url('https://fonts.googleapis.com/css2?family=Epilogue&display=swap');

@media (max-width: 600px) {
    html {
        font-size: 10px;
    }
}

body {
    margin: 0px;
    padding: 0px;
}

* {
    user-select: none;
}
.button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1.25rem 2.5rem;
    border-radius: 0rem 0rem 0rem 1.25rem;
    background: #1B29F0;
    border: 0px;

    color: #FFF;
    font-family: Epilogue;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    cursor: pointer;

    transition: all 0.3s;
}

.disabled {
    background: #CFCFCF;
    color: #575757;
}

.disabled path {
    fill: #575757;
}
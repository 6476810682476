.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.modal {
    padding: 3rem;
    background-color: white;
    border-radius: 0.75rem;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 0rem 1rem;
    box-sizing: border-box;
    gap: 1.5rem;
}

.form img {
    height: 3.5rem;
    width: 3.5rem;
}

.form span {
    max-width: 25rem;
    color: #575757;
    font-family: Epilogue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 1.2rem */
}

.form span a {
    text-decoration: none;
    text-transform: none;
    font-weight: bold;
    color: #1B29F0;
}

.messageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(255, 100, 100);
    font-family: Epilogue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    width: 25rem;
    height: 10rem;
    text-align: center;
}

.messageSuccess {
    color: #575757 !important;
}
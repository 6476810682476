.menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    height: 4.5rem;
    width: 100vw;
    background-color: white;
    box-sizing: border-box;
    padding: 1rem 3.5rem;
}

.menu img {
    height: 2.5rem;
}